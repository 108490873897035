<script>
import stats from '../config/stats.json';

export default {
  stats,
  methods: {
    getRarityColor(rarity) {
      switch(rarity) {
        case 'Legendary':
          return 'text-purple-500';
        case 'Epic':
          return 'text-blue-500';
        case 'Rare':
          return 'text-green-500';
        default:
          return 'text-gray-500';
      }
    }
  }
}
</script>