<template>
  <div>
    <div v-for="(attributes, layer) in layers" :key="layer">
      <h3 class="font-bold text-lg">{{ layer }}</h3>
      <ul class="my-5">
        <li v-for="attribute in attributes" :key="`${layer}_${attribute.attribute}`" class="flex my-2">
            <p :class="getRarityColor(attribute.rarity)">{{ attribute.attribute }}</p>
            <div class="flex-1 border-b-2 border-dotted border-gray-300"/>
            <p class="text-gray-500 text-right">{{ attribute.count }} ~ {{ (attribute.percentage * 100).toFixed(2) }}%</p>
          </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Rarity from '../mixins/Rarity.vue';

export default {
  computed: {
    layers() {
      const sorted = {};
      
      Object.keys(this.$options.stats).forEach(key => {
          if (!Object.prototype.hasOwnProperty.call(sorted, key)) {
              sorted[key] = [];
          }

          Object.keys(this.$options.stats[key]).forEach(attribute => {
              sorted[key].push({
                  attribute,
                  ...this.$options.stats[key][attribute]
              })
          });

          sorted[key].sort((a, b) => a.count - b.count);
      });

      return sorted;
    }
  },
  mixins: [Rarity]
}
</script>